import Vue from 'vue';
import Router from 'vue-router';

/* Add pages from views */
import Locator from './views/Locator.vue';
import GotSaved from './views/GotSaved.vue';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'GotSaved',
      component: GotSaved,
    },
    {
      path: '/got-saved',
      name: 'GotSaved',
      component: GotSaved,
    },
    {
      path: '/find-churches',
      name: 'Locator',
      component: Locator,
    },
  ],
});