<template>
  <div>   
    <v-row>
      <v-col cols="12" lg="6" offset-lg="3">
        <h1 class="mb-16">Got Saved?</h1>
        <div class="mb-5">
          Have you accepted the Lord Jesus as your personal savior?
          Please let us know below!
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!formSubmitted">
      <v-col cols="12" lg="6" offset-lg="3">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="member.Name"
              class="mt-2 mb-2"
              label="Name"
              outlined
              required
              :rules="[member.Name != '' || 'Please enter your name.']"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="member.Email"
              class="mt-5 mb-2"
              label="Email"
              outlined
              validate-on-blur
              :rules="[validateEmail(member.Email) || 'This is not a valid email.']"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-model="member.Phone"
              class="mt-5 mb-2"
              label="Phone"
              outlined
              hide-details="auto"
              maxlength="14"
              @input="acceptNumber()"
            ></v-text-field>
            <v-text-field
              v-model="member.Zipcode"
              class="mt-5 mb-2"
              label="Zipcode"
              outlined
              hide-details="auto"
            ></v-text-field>
            <!-- <v-checkbox class="mb-2" v-model="member.NeedToContact" label="I would like to be contacted by a pastor"></v-checkbox> -->

            <div class="text-right">
              <v-btn class="mb-1 mt-3" color="primary" depressed large @click="saveMember()" :loading="loading" :disabled="!formValid">
                Submit
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" lg="6" offset-lg="3">
        <v-card>
          <v-card-text>
            <div class="d-block mb-3">
              <v-icon size="50" block color="primary">mdi-check</v-icon>
            </div>

            Form submitted successfully. Check your email for more information about your decision!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row v-if="savedCounter > 50">
      <v-col v-if="savedCounter > 0">
        <h2 class="mt-16">People Saved</h2>
        <h1 class="mt-5 counter">{{ this.savedCounter }}</h1>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GotSaved",
  components: { },
  data: () => ({
    entries: [],
    loading: false,
    savedCounter: '',
    formSubmitted: false,
    member: {
      Name: '',
      Email: '',
      Phone: '',
      Zipcode: '',
      NeedToContact: false
    }
  }),
  computed: {
    formValid() {
      if (this.member.Name != '' && this.validateEmail(this.member.Email))
        return true
      else 
        return false
    }
  },
  watch: {
  },
  methods: {
    acceptNumber() {
      let x = this.member.Phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.member.Phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    validateEmail(email) {
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
    getSavedCount() {
      let url = "https://us-central1-vitalgathering-880b6.cloudfunctions.net/getSavedCount";

      axios.get(url).then((response) => {
        this.savedCounter = response.data
      });
    },
    saveMember() {
      this.loading = true;

      let url = "https://us-central1-vitalgathering-880b6.cloudfunctions.net/saveMember";
      // let url = "/api/saveMember";

      axios.post(url, { member: this.member })
      .then((response) => {
        this.formSubmitted = true;
        this.savedCounter = response.data
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      })
    }
  }
};
</script>

<style scoped>
.counter {
    opacity: 1;
    animation: fade 2s linear;
}


@keyframes fade {
  0% { opacity: 0 }
  50% { opacity: 0.5 }
  100% { opacity: 1 }
}
</style>
