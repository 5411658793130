<template>
  <div>
    <h1 class="py-5 text-left white--text">Find a Church</h1>

    <v-row>
      <v-col cols="12" md="6">
        <!-- <div class="text-left white--text mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </div> -->
      </v-col>
    </v-row>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              class="mt-5 mb-2"
              label="Enter Your Address"
              outlined
              hide-details="auto"
              @keydown.enter="searchAddress()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="6">
            <v-card outlined>
              <v-card-text style="height: 700px;">
                <gmaps-map :options="mapOptions">
                  <gmaps-marker
                    v-for="(loc, i) in markers"
                    :key="i"
                    :position="loc.coordinates"
                    :title="loc.name"
                    :icon="require('../assets/marker-default.png')"
                    @click="showMarkerPopup(loc)"
                  />

                  <gmaps-marker
                    v-if="searchedByAddress"
                    :position="userAddress.coordinates"
                    :title="userAddress.address"
                    :icon="require('../assets/marker-user.png')"
                  />

                  <gmaps-info-window ref="markerInfo" :options="activeMarkerLocation">
                    <v-card flat>
                      <v-card-title>{{ activeMarkerName }}</v-card-title>
                      <v-card-text class="text-left">
                        <div class="subtitle-2">
                          {{ activeMarkerAddress }}
                        </div>
                        <v-btn
                          class="mt-4"
                          outlined
                          color="primary"
                          :href="'https://www.google.com/maps/dir//' + activeMarkerAddress"
                          target="blank"
                        >
                          <v-icon class="mr-3">mdi-map</v-icon>
                          Get Directions
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </gmaps-info-window>
                </gmaps-map>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="6">
            <v-card outlined>
              <div class="church-list">
                <v-expansion-panels accordion>
                  <v-expansion-panel v-for="(loc, i) in markers" :key="i" class="text-left">
                    <v-expansion-panel-header>
                      {{ loc.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="primary--text">
                      Church Address: {{ loc.address }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-card>
      <v-row>
        <v-col>
          <button @click="geocode">Geocode</button>
        </v-col>
      </v-row>
    </v-card> -->
  </div>
</template>

<script>
import axios from "axios";
import { gmapsMap, gmapsMarker, gmapsInfoWindow } from "x5-gmaps";
import addresses from "../assets/addressList.json";
import locations from "../assets/locations.json";

export default {
  name: "Locator",
  components: { gmapsMap, gmapsMarker, gmapsInfoWindow },
  data: () => ({
    addressList: addresses.addresses,
    geocodeData: [], // used only for manual geocoding
    search: '',
    locationList: locations,
    searchedByAddress: false,
    userAddress: {
      address: '',
      coordinates: {}
    },
    activeMarkerLocation: {
      position: {}
    },
    activeMarkerName: "",
    activeMarkerAddress: "",
    mapOptions: {
      center: { lat: 27.23, lng: -82.319 },
      zoom: 10
    }
  }),
  mounted() {
    // this.geocode();
  },
  computed: {
    markers() {
      return this.locationList.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
    }
  },
  watch: {
  },
  methods: {
    showMarkerPopup(loc) {
      this.activeMarkerLocation = {
        position: loc.coordinates
      };
      this.activeMarkerName = loc.name;
      this.activeMarkerAddress = loc.address;
      this.$refs.markerInfo.open();
    },
    searchAddress() {
      const key = "AIzaSyCm3VSC1gsb9KZj6UyOn7q_0YKCY1SMhls";

      if (this.search != '') {
        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.search}&key=${key}`)
          .then(res => {
            this.searchedByAddress = true;
  
            // update center of the map
            this.mapOptions.center = res.data.results[0].geometry.location;
            this.mapOptions.zoom = 14;
  
            // save coordinates to display new marker for searched address
            this.userAddress.coordinates = res.data.results[0].geometry.location;
            this.userAddress.address = res.data.results[0].formatted_address;
          })
          .catch(() => {
            this.searchedByAddress = false;
            this.mapOptions = {
              center: { lat: 27.23, lng: -82.319 },
              zoom: 10
            }
          });
      } else {
        this.searchedByAddress = false;
        this.mapOptions = {
          center: { lat: 27.23, lng: -82.319 },
          zoom: 10
        }        
      }
    },
    geocode() {
      const key = "AIzaSyCm3VSC1gsb9KZj6UyOn7q_0YKCY1SMhls";
      this.addressList.forEach(a => {
        // console.log(a.address);
        axios
          .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${a.address}&key=${key}`)
          .then(res => {
            console.log(res);

            const newLoc = {};
            newLoc.coordinates = res.data.results[0].geometry.location;
            newLoc.address = res.data.results[0].formatted_address;
            newLoc.name = a.name

            this.geocodeData.push(newLoc);
          });
      });
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.church-list {
  max-height: 700px;
  overflow-y: scroll;
}
</style>
