import Vue from 'vue';
import x5GMaps from 'x5-gmaps';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false;
Vue.use(x5GMaps, 'AIzaSyCm3VSC1gsb9KZj6UyOn7q_0YKCY1SMhls');
Vue.use(x5GMaps, { key: 'AIzaSyCm3VSC1gsb9KZj6UyOn7q_0YKCY1SMhls', libraries: ['places'] });

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
