import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: { 
    dark: true,
    themes: {
      dark: {
        primary: '#d19525',
        secondary: '#FF7C51',
        accent: '#11A0EE',
      }
    }
  },
});
