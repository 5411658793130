<template>
  <v-app>
    <v-app-bar
      flat
      height="110"
      color="transparent"
      class="toolbar"
    >
      <v-img src="https://images.squarespace-cdn.com/content/v1/652ee1743e374b08a062ab1f/2cd2a0ea-3af6-4681-9650-a866cf169035/IMG-5195-768x768+1.png" max-width="220"></v-img>

      <v-spacer></v-spacer>

      <div class="menu d-none d-md-block">
        <router-link to="/got-saved" class="nav-link w-nav-link float-right white--text">GOT SAVED?</router-link>
        <router-link to="/find-churches" class="nav-link w-nav-link float-right white--text">FIND A CHURCH</router-link>
        <a href="https://www.globalrevivalnow.com/sarasota" class="nav-link w-nav-link float-right white--text">← HOME</a>
      </div>
      <v-app-bar-nav-icon class="d-md-none"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-content>
      <div class="bg-wave"></div>
      <v-container class="mt-16">
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
};
</script>

<style>
#app {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

header {
  flex: none !important;
}

h1 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 50px;
}

.bg-wave {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://www.globalrevivalnow.com/vital/images/Rainbow-Waves_web.jpg');
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.11;
}

@media (min-width: 1247px) {
  .toolbar {
    padding: 0 16rem;
  }
}

@media (min-width: 1904px) {
.container {
    max-width: 100% !important;
    padding-right: 10vh !important;
    padding-left: 10vw !important;
  }
}

.nav-link {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.w-nav-link {
    position: relative;
    text-decoration: none;
    color: #222222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}
</style>
